import React from "react";

import Layout from "../components/Layout";
import Container from "../components/Container";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main>
      <Container>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Container>
    </main>
  </Layout>
);

export default NotFoundPage;
